html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; 
  background-color: black;
  color: white;
  }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

::-moz-selection {
  background-color: #e74c3c;
  color: white; }

::selection {
  background-color: #e74c3c;
  color: white; }

::-webkit-input-placeholder {
  color: #bdc3c7;
  font-family: "Clear Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  line-height: 1; }

:-moz-placeholder {
  color: #bdc3c7;
  font-family: "Clear Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  line-height: 1; }

::-moz-placeholder {
  color: #bdc3c7;
  font-family: "Clear Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  line-height: 1; }

:-ms-input-placeholder {
  color: #bdc3c7;
  font-family: "Clear Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  line-height: 1; }

::placeholder {
  color: #bdc3c7;
  font-family: "Clear Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  line-height: 1; }

html {
  color: #111111;
  font-family: "Clear Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5rem;
  -webkit-transition: -webkit-box-shadow 0;
  transition: box-shadow 0;
  text-rendering: optimizeLegibility; }

@media screen and (min-width: 30em) {
  body {
    line-height: 1.6875rem; } }
@media screen and (min-width: 70em) {
  body {
    line-height: 1.875rem; } }

h1,
.h1 {
  font-weight: 600;
  line-height: 3rem; }
  @media screen and (min-width: 45em) {
    h1,
    .h1 {
      line-height: 3.75rem; } }
  @media screen and (min-width: 70em) {
    h1,
    .h1 {
      line-height: 4.5rem; } }

h2,
.h2 {
  font-weight: 600; }
  @media screen and (min-width: 30em) {
    h2,
    .h2 {
      line-height: 1.875rem; } }
  @media screen and (min-width: 45em) {
    h2,
    .h2 {
      line-height: 2.25rem; } }
  @media screen and (min-width: 70em) {
    h2,
    .h2 {
      line-height: 2.625rem; } }

h3,
.h3 {
  font-weight: 600;
  letter-spacing: .04rem;
  text-align: center; }
  @media screen and (min-width: 30em) {
    h3,
    .h3 {
      line-height: 1.6875rem; } }
  @media screen and (min-width: 45em) {
    h3,
    .h3 {
      line-height: 1.875rem; } }
  @media screen and (min-width: 70em) {
    h3,
    .h3 {
      line-height: 2.0625rem; } }

h3 {
  margin-bottom: 0.75rem; }

h4,
.h4 {
  line-height: 1.2rem; }
  @media screen and (min-width: 30em) {
    h4,
    .h4 {
      line-height: 1.5rem; } }
  @media screen and (min-width: 70em) {
    h4,
    .h4 {
      line-height: 1.6875rem; } }

h5,
.h5 {
  line-height: 1rem; }
  @media screen and (min-width: 30em) {
    h5,
    .h5 {
      line-height: 1.2rem; } }
  @media screen and (min-width: 70em) {
    h5,
    .h5 {
      line-height: 1.5rem; } }

h1,
h2,
ol,
ul,
p,
blockquote,
input,
label {
  margin-bottom: 0.75rem; }
  @media screen and (min-width: 45em) {
    h1,
    h2,
    ol,
    ul,
    p,
    blockquote,
    input,
    label {
      margin-bottom: 1.5rem; } }

ul {
  list-style-type: disc; }

ul, ol {
  padding-left: 1.5rem; }
  @media screen and (min-width: 45em) {
    ul, ol {
      padding-left: 0; } }

strong, b, .bold {
  font-weight: 600; }

em, .italic {
  font-style: italic; }

img {
  border-radius: 0.3rem;
  max-width: 100%; }

input, button {
  -webkit-appearance: none;
  -moz-appearance: none; }

abbr {
  border-bottom: 0.075rem dotted #111111;
  cursor: help; }

button {
  width: 100%;
  -webkit-transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 -webkit-box-shadow;
  transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 box-shadow; }

input[type="submit"] {
  border: 0;
  font-family: "Clear Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }

input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"] {
  border: 0.225rem solid #bdc3c7;
  border-radius: 0.3rem;
  display: block;
  outline: none;
  width: 100%;
  padding: 0.75rem; }

.wrap {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (min-width: 45em) {
    .wrap {
      width: 50rem; } }
  @media screen and (min-width: 60em) {
    .wrap {
      width: 65rem; } }
  @media screen and (min-width: 70em) {
    .wrap {
      width: 80rem; } }

.wrap-log-in {
  display: block;
  margin: 0 auto;
  max-width: 30rem;
  padding: 0 1.5rem; }

.measure {
  margin-right: auto;
  margin-left: auto;
  max-width: 90%; }
  @media screen and (min-width: 30em) {
    .measure {
      width: 35rem; } }
  @media screen and (min-width: 45em) {
    .measure {
      width: 42rem; } }
  @media screen and (min-width: 60em) {
    .measure {
      width: 46rem; } }

.gw {
  margin-right: -0.75rem; }
  @media screen and (min-width: 60em) {
    .gw {
      margin-right: -1.5rem; } }

.gw-half-pad {
  margin-right: -0.75rem; }

.g, .gs {
  display: block;
  float: left;
  overflow: hidden;
  padding-right: 0.75rem;
  position: relative; }
  @media screen and (min-width: 60em) {
    .g {
      padding-right: 1.5rem; } }
      
  @media screen and (min-width: 60em) {
    .gs {
      padding-right: 0.75rem; } }

.g-pad {
  display: block;
  float: left;
  overflow: hidden;
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative; }
  @media screen and (min-width: 60em) {
    .g-pad {
      padding-right: 1.5rem;
      padding-bottom: 1.5rem; } }

.g-half-pad {
  display: block;
  float: left;
  overflow: hidden;
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative; }

.g--no-pad {
  display: block;
  float: left;
  overflow: hidden;
  position: relative; }

.g-one-whole {
  width: 100%; }

.g-one-half {
  width: 50%; }

.g-one-third {
  width: 33.333333%; }

.g-two-thirds {
  width: 66.66667%; }

.g-one-quarter {
  width: 25%; }

.g-three-quarters {
  width: 75%; }

.g-one-fifth {
  width: 20%; }

.g-two-fifths {
  width: 40%; }

.g-three-fifths {
  width: 60%; }

.g-four-fifths {
  width: 80%; }

.g-omega {
  float: right; }

@media screen and (min-width: 30em) {
  .g-S-one-whole {
    width: 100%; }

  .g-S-one-half {
    width: 50%; }

  .g-S-one-third {
    width: 33.333333%; }

  .g-S-two-thirds {
    width: 66.66667%; }

  .g-S-one-quarter {
    width: 25%; }

  .g-S-one-fifth {
    width: 20%; }

  .g-S-two-fifths {
    width: 40%; }

  .g-S-three-fifths {
    width: 60%; }

  .g-S-four-fifths {
    width: 80%; }

  .g-S-omega {
    float: right; } }
@media screen and (min-width: 45em) {
  .g-M-one-whole {
    width: 100%; }

  .g-M-one-half {
    width: 50%; }

  .g-M-one-third {
    width: 33.333333%; }

  .g-M-two-thirds {
    width: 66.66667%; }

  .g-M-one-quarter {
    width: 25%; }

  .g-M-one-fifth {
    width: 20%; }

  .g-M-two-fifths {
    width: 40%; }

  .g-M-three-fifths {
    width: 60%; }

  .g-M-four-fifths {
    width: 80%; }

  .g-M-omega {
    float: right; } }
@media screen and (min-width: 52em) {
  .g-mL-one-whole {
    width: 100%; }

  .g-mL-one-half {
    width: 50%; }

  .g-mL-one-third {
    width: 33.333333%; }

  .g-mL-two-thirds {
    width: 66.66667%; }

  .g-mL-one-quarter {
    width: 25%; }

  .g-mL-one-fifth {
    width: 20%; }

  .g-mL-two-fifths {
    width: 40%; }

  .g-mL-three-fifths {
    width: 60%; }

  .g-mL-four-fifths {
    width: 80%; }

  .g-mL-omega {
    float: right; } }
@media screen and (min-width: 60em) {
  .g-L-one-whole {
    width: 100%; }

  .g-L-one-half {
    width: 50%; }

  .g-L-one-third {
    width: 33.333333%; }

  .g-L-two-thirds {
    width: 66.666666%; }

  .g-L-one-quarter {
    width: 25%; }

  .g-L-one-fifth {
    width: 20%; }

  .g-L-two-fifths {
    width: 40%; }

  .g-L-three-fifths {
    width: 60%; }

  .g-L-four-fifths {
    width: 80%; }

  .g-L-omega {
    float: right; } }
@media screen and (min-width: 70em) {
  .g-XL-one-whole {
    width: 100%; }

  .g-XL-one-half {
    width: 50%; }

  .g-XL-one-third {
    width: 33.333333%; }

  .g-XL-one-quarter {
    width: 25%; }

  .g-XL-omega {
    float: right; } }
@media screen and (min-width: 90em) {
  .g-XXL-one-whole {
    width: 100%; }

  .g-XXL-one-half {
    width: 50%; }

  .g-XXL-one-third {
    width: 33.333333%; }

  .g-XXL-one-quarter {
    width: 25%; }

  .g-XXL-omega {
    float: right; } }
.g-pre-one-third {
  left: 33.333333%; }

.g-pre-one-fifth {
  left: 20%; }

.g-pre-two-fifths {
  left: 40%; }

@media screen and (min-width: 30em) {
  .g-S-pre-one-fifth {
    left: 20%; } }
.site-header {
  margin-bottom: 3rem; }
  @media screen and (min-width: 30em) {
    .site-header {
      margin-bottom: 4.5rem; } }
  @media screen and (min-width: 45em) {
    .site-header {
      margin-bottom: 6rem; } }

.site-footer {
  margin-top: 3rem; }
  @media screen and (min-width: 30em) {
    .site-footer {
      margin-top: 4.5rem; } }
  @media screen and (min-width: 45em) {
    .site-footer {
      margin-top: 6rem; } }

/* main >:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}
 */
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.block--S {
  margin-bottom: 3rem; }

.block--M {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem; }
  @media screen and (min-width: 30em) {
    .block--M {
      margin-bottom: 3rem;
      padding-bottom: 1.5rem; } }
  @media screen and (min-width: 45em) {
    .block--M {
      margin-bottom: 4.5rem;
      padding-bottom: 3rem; } }

.block-up--M {
  margin-top: 1.5rem;
  padding-top: 1.5rem; }
  @media screen and (min-width: 30em) {
    .block-up--M {
      margin-top: 3rem;
      padding-top: 1.5rem; } }
  @media screen and (min-width: 45em) {
    .block-up--M {
      margin-top: 4.5rem;
      padding-top: 3rem; } }

.block--L {
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 30em) {
    .block--L {
      margin-bottom: 4.5rem;
      padding-bottom: 3rem; } }
  @media screen and (min-width: 45em) {
    .block--L {
      margin-bottom: 6rem;
      padding-bottom: 4.5rem; } }

.m-b-0 {
  margin-bottom: 0; }

.m-b-half {
  margin-bottom: 0.75rem; }

.m-b-1 {
  margin-bottom: 1.5rem; }

.m-b-2 {
  margin-bottom: 3rem; }

.m-b-3 {
  margin-bottom: 4.5rem; }

.m-b-4 {
  margin-bottom: 6rem; }

.m-t-2 {
  margin-top: 3rem; }

.m-t-3 {
  margin-top: 4.5rem; }

.rm-bottom-margin-padding*:last-child {
  margin-bottom: 0;
  padding-bottom: 0; }

.animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s; }

.animatedDelay1 {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms; }

.animatedDelay2 {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-delay: 750ms;
  animation-delay: 750ms; }

.animatedDelay3 {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-delay: 1250ms;
  animation-delay: 1250ms; }

.animatedDelay4 {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-delay: 1750ms;
  animation-delay: 1750ms; }

@-webkit-keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }

  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -ms-transform: scale(0.3);
    transform: scale(0.3); }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05); }

  70% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9); }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); } }
.bounce-in {
  -webkit-animation-name: bounce-in;
  animation-name: bounce-in; }

@-webkit-keyframes bounce--in-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }

  80% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounce--in-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px); }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); }

  80% {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px); }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
.bounce--in-up {
  -webkit-animation-name: bounce--in-up;
  animation-name: bounce--in-up; }

@-webkit-keyframes bounce--in-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }

  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounce--in-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px); }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }

  80% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
.bounce--in-down {
  -webkit-animation-name: bounce--in-down;
  animation-name: bounce--in-down; }

@-webkit-keyframes fade--in {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@keyframes fade--in {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }
.fade--in {
  -webkit-animation-name: fade--in;
  animation-name: fade--in; }

@-webkit-keyframes fade--in-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fade--in-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
.fade--in-up {
  -webkit-animation-name: fade--in-up;
  animation-name: fade--in-up; }

.border-t-1 {
  border-top: 0.225rem solid #ecf0f1; }

.border-r-1 {
  border-right: 0.225rem solid #ecf0f1; }

.border-b-1 {
  border-bottom: 0.225rem solid #ecf0f1; }

.border-l-1 {
  border-left: 0.225rem solid #ecf0f1; }

.btn,
input[type="submit"] {
  border: 0;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  font-family: "Clear Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  line-height: 1.2rem;
  margin-bottom: 0.95rem;
  outline: none;
  padding: 1.875rem;
  text-align: center;
  -webkit-transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 -webkit-box-shadow;
  transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 box-shadow; }
  @media screen and (min-width: 45em) {
    .btn,
    input[type="submit"] {
      line-height: 1.5rem; } }
  @media screen and (min-width: 60em) {
    .btn,
    input[type="submit"] {
      margin-bottom: 1.0rem; } }

.btn-lowercase {
  border: 0;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  font-family: "Clear Sans", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  line-height: 1.2rem;
  margin-bottom: 0.95rem;
  outline: none;
  padding: 1.875rem;
  text-align: center;
  -webkit-transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 -webkit-box-shadow;
  transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 box-shadow; }
  @media screen and (min-width: 45em) {
    .btn-lowercase {
      line-height: 1.5rem; } }
  @media screen and (min-width: 60em) {
    .btn-lowercase {
      margin-bottom: 1.7rem; } }

.btn--no-marg {
  margin-bottom: 0.2rem; }

@media screen and (min-width: 45em) {
  .btn--half-marg {
    margin-bottom: 0.95rem; } }

.btn--quarter-marg {
  margin-bottom: 0.575rem; }

.btn--no-marg {
  margin-bottom: 0.2rem; }

.btn--XS {
  padding: 0.85714rem 0.85714rem; }

.btn--S {
  padding: 1.2rem 1.2rem; }

.btn--L {
  padding: 2.25rem 3rem; }

.btn--XL {
  padding: 3rem 4.5rem; }

.btn--fill,
input[type="submit"] {
  width: 100%; }

@media screen and (min-width: 30em) {
  .btn-cap {
    max-width: 10rem; }

  .btn-cap--M {
    max-width: 14rem; }

  .btn-cap--L {
    max-width: 18rem; } }
.btn--center, input[type="submit"] {
  margin-right: auto;
  margin-left: auto; }

.btn--push {
  color: white;
  position: relative; }
  .btn--push:hover, .btn--push:focus {
    color: white; }
  .btn--push:active {
    color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
    top: 0.2rem; }

.btn--push--1 {
  -webkit-box-shadow: 0 0.2rem #d62c1a;
  box-shadow: 0 0.2rem #d62c1a;
  background-color: #e74c3c;
  text-shadow: 0 1px #a82315; }
  .btn--push--1:hover, .btn--push--1:focus {
    background-color: #ea6153; }
  .btn--push--1:active {
    background-color: #e43725; }

.btn--push--2 {
  -webkit-box-shadow: 0 0.2rem #1e8449;
  box-shadow: 0 0.2rem #1e8449;
  background-color: #27ae60;
  text-shadow: 0 1px #145b32; }
  .btn--push--2:hover, .btn--push--2:focus {
    background-color: #2cc36b; }
  .btn--push--2:active {
    background-color: #229955; }

.btn--push--orange {
  -webkit-box-shadow: 0 0.2rem #da7900;
  box-shadow: 0 0.2rem #da7900;
  background-color: #df9900;
  text-shadow: 0 1px #a97500 }
  .btn--push--orange:hover, .btn--push--orange:focus {
    background-color: #efa900; }
  .btn--push--orange:active {
    background-color: #cf8900 }

.btn--push--3 {
  -webkit-box-shadow: 0 0.2rem #20638f;
  box-shadow: 0 0.2rem #20638f;
  background-color: #2980b9;
  text-shadow: 0 1px #164666; }
  .btn--push--3:hover, .btn--push--3:focus {
    background-color: #2e8ece; }
  .btn--push--3:active {
    background-color: #2472a4; }

.btn--push--4 {
  -webkit-box-shadow: 0 0.2rem #d62c1a;
  box-shadow: 0 0.2rem #d62c1a;
  background-color: #e74c3c;
  text-shadow: 0 1px #a82315; }
  .btn--push--4:hover, .btn--push--4:focus {
    background-color: #ea6153; }
  .btn--push--4:active {
    background-color: #e43725; }

.btn--push--dark {
  -webkit-box-shadow: 0 0.2rem black;
  box-shadow: 0 0.2rem black;
  background-color: #111111;
  text-shadow: 0 1px black; }
  .btn--push--dark:hover, .btn--push--dark:focus {
    background-color: #1e1e1e; }
  .btn--push--dark:active {
    background-color: #040404; }

.btn--push--dark-grey {
  -webkit-box-shadow: 0 0.2rem #798d8f;
  box-shadow: 0 0.2rem #798d8f;
  background-color: #95a5a6;
  text-shadow: 0 1px #617374; }
  .btn--push--dark-grey:hover, .btn--push--dark-grey:focus {
    background-color: #a3b1b2; }
  .btn--push--dark-grey:active {
    background-color: #87999a; }

.btn--push--fb {
  -webkit-box-shadow: 0 0.2rem #2d4373;
  box-shadow: 0 0.2rem #2d4373;
  background-color: #3b5998;
  text-shadow: 0 1px #1e2e4f; 
  }
  .btn--push--fb:hover, .btn--push--fb:focus {
    background-color: #4264aa; 
    }
  .btn--push--fb:active {
    background-color: #344e86; 
    }

.btn--push--google {
  -webkit-box-shadow: 0 0.2rem #d2d0bf;
  box-shadow: 0 0.2rem #d2d0bf;
  background-color: #e7e6dd;
  text-shadow: 0 1px #bdbaa1; }
  .btn--push--google:hover, .btn--push--google:focus {
    background-color: #f2f1ec; }
  .btn--push--google:active {
    background-color: #dcdbce; }

.btn--push--google:before {
  color: #db4437;
  content: "\E803";
  font-family: "icon";
  position: absolute;
  right: 90%; }
  @media screen and (min-width: 30em) {
    .btn--push--google:before {
      right: 85%; } }

.btn--push--yahoo {
  -webkit-box-shadow: 0 0.2rem #500a6f;
  box-shadow: 0 0.2rem #500a6f;
  background-color: #720e9e;
  text-shadow: 0 1px #2e0640; }
  .btn--push--yahoo:hover, .btn--push--yahoo:focus {
    background-color: #8310b5; }
  .btn--push--yahoo:active {
    background-color: #610c87; }

.btn--push--raven {
  -webkit-box-shadow: 0 0.2rem #0a3c43;
  box-shadow: 0 0.2rem #0a3c43;
  background-color: #106470;
  text-shadow: 0 1px #031417; }
  .btn--push--raven:hover, .btn--push--raven:focus {
    background-color: #137886; }
  .btn--push--raven:active {
    background-color: #0d505a; }

.btn-sink--1 {
  background-color: #95a5a6;
  -webkit-box-shadow: 0 0.2rem #798d8f;
  box-shadow: 0 0.2rem #798d8f;
  color: white;
  text-shadow: 0 0.075rem #617374; }
  .btn-sink--1:hover, .btn-sink--1:focus {
    background-color: #a3b1b2;
    -webkit-box-shadow: 0 0.2rem #87999a;
    box-shadow: 0 0.2rem #87999a; }
  .btn-sink--1:active {
    background-color: #617374;
    -webkit-box-shadow: inset 0 0.2rem #333c3c, inset 0 0.2rem 0.4rem #333c3c;
    box-shadow: inset 0 0.2rem #333c3c, inset 0 0.2rem 0.4rem #333c3c;
    color: #ecf0f1;
    cursor: pointer;
    top: 0.2rem; }

.btn--flat {
  color: white; }

.btn--flat--green {
  background-color: #27ae60; }
  .btn--flat--green:hover, .btn--flat--green:focus {
    background-color: #2cc36b; }
  .btn--flat--green:active {
    background-color: #229955; }

.btn--flat--red {
  background-color: #e74c3c; }
  .btn--flat--red:hover, .btn--flat--red:focus {
    background-color: #ea6153; }
  .btn--flat--red:active {
    background-color: #e43725; }

.btn--flat--dark-grey {
  background-color: #95a5a6; }
  .btn--flat--dark-grey:hover, .btn--flat--dark-grey:focus {
    background-color: #a3b1b2; }
  .btn--flat--dark-grey:active {
    background-color: #87999a; }

.cf, .gw, .gw-half-pad, .gw--no-pad, .island,
.islet, .site-header {
  zoom: 1; }
  .cf:before, .cf:after, .gw:before, .gw-half-pad:before, .gw--no-pad:before, .island:before,
  .islet:before, .site-header:before, .gw:after, .gw-half-pad:after, .gw--no-pad:after, .island:after,
  .islet:after, .site-header:after {
    content: "";
    display: table; }
  .cf:after, .gw:after, .gw-half-pad:after, .gw--no-pad:after, .island:after,
  .islet:after, .site-header:after {
    clear: both; }

.google-blue {
  color: #4285f4; }

.google-red {
  color: #db4437; }

.google-yellow {
  color: #f4b400; }

.google-green {
  color: #0f9d58; }

.doc > header, .doc > section {
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 45em) {
    .doc > header, .doc > section {
      margin-bottom: 2.25rem; } }

.doc > header {
  margin-top: 1.5rem; }
  @media screen and (min-width: 30em) {
    .doc > header {
      margin-top: 3rem; } }
  @media screen and (min-width: 45em) {
    .doc > header {
      margin-top: 4.5rem; } }



.site-footer {
  border-top: 0.225rem solid #ecf0f1;
  padding: 1.5rem 0;
  text-align: center; }

.site-footer a {
  border-bottom-color: rgba(255, 255, 255, 0); }

.red-form__label {
  color: #e74c3c; }

.red-form__input {
  margin-bottom: 0.75rem; }

.red-form__input:focus {
  outline: 0;
  border-color: #e74c3c; }

.red-form__submit {
  margin-top: 3rem; }

.green-form__label {
  color: #27ae60; }

.green-form__input {
  margin-bottom: 0.75rem; }

.green-form__input:focus {
  outline: 0;
  border-color: #27ae60; }

.green-form__submit {
  margin-top: 3rem; }

.marg-remove-last > :last-child {
  margin-bottom: 0;
  padding-bottom: 0; }

.how-do-I__grid {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 30em) {
    .how-do-I__grid {
      margin-top: 3rem;
      margin-bottom: 3rem; } }

.how-do-I__grid h2 {
  text-align: left; }

.how-do-I__grid .grid__block--1-2-3 {
  position: relative; }
  @media screen and (min-width: 30em) {
    .how-do-I__grid .grid__block--1-2-3 {
      min-height: 14rem; } }
  @media screen and (min-width: 45em) {
    .how-do-I__grid .grid__block--1-2-3 {
      min-height: 12rem; } }
  @media screen and (min-width: 60em) {
    .how-do-I__grid .grid__block--1-2-3 {
      min-height: 24rem; } }
  @media screen and (min-width: 70em) {
    .how-do-I__grid .grid__block--1-2-3 {
      min-height: 22rem; } }

.icon-wrapper:hover > .icon:after, .icon-wrapper:hover > .icon:before, .icon-wrapper:active > .icon:after, .icon-wrapper:active > .icon:before, .icon-wrapper:focus > .icon:after, .icon-wrapper:focus > .icon:before {
  opacity: 1; }

.icon {
  border-bottom: 0;
  font-family: icon;
  position: relative;
  speak: none; }
  .icon:after, .icon:before {
    font-family: icon;
    -webkit-transition: 150ms all ease-in-out;
    transition: 150ms all ease-in-out;
    vertical-align: middle; }

.icon--before {
  padding-right: 0.75rem; }

.icon--after {
  padding-left: 0.75rem; }

.icon--center:before, .icon--center:after {
  text-align: center; }

.icon--inline:before, .icon--inline:after {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  @media screen and (min-width: 30em) {
    .icon--inline:before, .icon--inline:after {
      padding-bottom: 3rem; } }

.icon-plus:before {
  content: "\E800"; }

.icon-minus:before {
  content: "\E801"; }

.icon-tick:after {
  content: "\E802";
  color: #27ae60; }

.icon-google:before {
  content: "\E803"; }

.credit-fig {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0.75rem;
  max-width: 100%; }

.credit-figcap {
  max-width: 280px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0.75rem; }

.img-inline {
  margin-bottom: 1.5rem; }

.four04 {
  width: 30rem; }

.sub-index {
  border-bottom: 0.225rem solid #ecf0f1;
  cursor: pointer;
  display: block;
  position: relative; }
  .sub-index:first-of-type {
    border-top: 0.225rem solid #ecf0f1; }

.sub-index__title {
  display: inline-block;
  margin: 0 auto;
  padding: 1.5rem 0 1.5rem 0.75rem;
  position: relative;
  -webkit-transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 -webkit-box-shadow;
  transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 box-shadow; }
  @media screen and (min-width: 45em) {
    .sub-index__title {
      padding-left: 1.5rem; } }

.sub-index__completion {
  color: #bdc3c7;
  display: inline-block;
  font-weight: 400;
  padding-left: .5rem;
  -webkit-transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 -webkit-box-shadow;
  transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 box-shadow; }
  @media screen and (min-width: 45em) {
    .sub-index__completion {
      padding-left: 0.75rem; } }

.sub-index__show {
  display: block;
  padding: 1.5rem 0.75rem 1.5rem 0;
  text-align: right; }
  @media screen and (min-width: 45em) {
    .sub-index__show {
      padding-right: 1.5rem; } }

.sub-index__list {
  display: none;
  padding-left: 0.75rem; }
  @media screen and (min-width: 45em) {
    .sub-index__list {
      padding-left: 1.5rem; } }

.island,
.islet {
  display: block; }

.island {
  padding: 1.5rem 1.5rem 0 1.5rem; }

.islet {
  padding: 0.75rem 0.75rem 0 0.75rem; }

.islet-S {
  padding: 0.375rem 0.375rem 0 0.375rem; }

.island-full {
  padding: 1.5rem; }

.islet-full {
  padding: 0.75rem; }

@media screen and (min-width: 30em) {
  .island-full-S {
    padding: 1.5rem; } }

@media screen and (min-width: 45em) {
  .island-M {
    padding: 1.5rem 1.5rem 0 1.5rem; } }

.island--center {
  text-align: center; }

@media screen and (min-width: 30em) {
  .island-S {
    padding: 1.5rem 1.5rem 0 1.5rem; } }

@media screen and (min-width: 45em) {
  .island-M {
    padding: 1.5rem 1.5rem 0 1.5rem; } }

.island--grey {
  background-color: #ecf0f1;
  border-radius: 0.3rem;
  margin-bottom: 1.5rem; }

.island--green {
  border: 0.225rem solid #89e5b0;
  border-radius: 0.3rem; }

.island--blue {
  border: 0.225rem solid #94c6e7;
  border-radius: 0.3rem; }

.list--indented {
  padding-left: 1.5rem; }

.list--indented--double {
  padding-left: 3rem; }

.list-blank {
  list-style-type: none; }

.log-in__label {
  color: #e74c3c; }

.sign-up__label {
  color: #27ae60; }

.log-in__input, .sign-up__input {
  margin-bottom: 0.75rem; }

.log-in__submit, .sign-up__submit {
  margin-top: 3rem; }

.logo-nav {
  display: block;
  margin: 0.75rem auto;
  height: 4.5rem; }
  @media screen and (min-width: 60em) {
    .logo-nav {
      margin: 0;
      padding: 0.75rem 0 0.75rem 0.75rem; } }

.logo-nav__link {
  height: 4.5rem; }

.logo-big {
  font-size: 19.2px;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  letter-spacing: .02rem; }
  @media screen and (min-width: 30em) {
    .logo-big {
      font-size: 35.2px;
      font-size: 2.2rem; } }
  @media screen and (min-width: 45em) {
    .logo-big {
      font-size: 41.6px;
      font-size: 2.6rem; } }
  @media screen and (min-width: 60em) {
    .logo-big {
      font-size: 44.8px;
      font-size: 2.8rem; } }
  @media screen and (min-width: 70em) {
    .logo-big {
      font-size: 48px;
      font-size: 3rem; } }

.logo-big__img {
  max-width: 50%; }

.m-b-0 {
  margin-bottom: 0; }

.m-b-half {
  margin-bottom: 0.75rem; }

.m-b-1 {
  margin-bottom: 1.5rem; }

@media screen and (min-width: 30em) {
  .m-S-b-half {
    margin-bottom: 0.75rem; }

  .m-S-b-1 {
    margin-bottom: 1.5rem; } }
@media screen and (min-width: 45em) {
  .m-M-b-half {
    margin-bottom: 0.75rem; }

  .m-M-b-1 {
    margin-bottom: 1.5rem; } }
@media screen and (min-width: 60em) {
  .m-L-b-half {
    margin-bottom: 0.75rem; }

  .m-L-b-1 {
    margin-bottom: 1.5rem; } }
.masthead {
  text-align: center; }

.masthead--red {
  background-color: #e74c3c;
  color: #e74c3c; }

.masthead--blue {
  background-color: #34495e;
  color: white; }

.problem h1, .problem h2, .problem h3 {
  text-align: center; }

.prob-specific {
  color: #27ae60; }

.prob-general {
  color: #2980b9; }

.question, .hints {
  margin-bottom: 1.5rem; }

.question h3, .answer h3 {
  display: inline; }

.problem-img {
  background-color: black;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0.75rem; }

.hints__title {
  margin-top: 0.75rem;
  margin-bottom: 0; }
  @media screen and (min-width: 30em) {
    .hints__title {
      margin-top: 0; } }

.hints__title, p[class^="hints__column-title"] {
  font-weight: 400; }

p[class^="hints__column-title"] {
  text-align: center;
  margin-bottom: 0; }
  @media screen and (min-width: 30em) {
    p[class^="hints__column-title"] {
      margin-bottom: 1.5rem; } }

.hints__column-title--general {
  color: #2980b9; }

.hints__column-title--specific {
  color: #27ae60; }

.hints__wrap__top {
  background-color: #6ab0de;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding: 0.75rem;
  margin: 0.75rem 0.75rem 0 0.75rem; }

.hints__wrap__bottom {
  background-color: #95a5a6;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  padding: 0 0.75rem 0.75rem;
  margin: 0 0.75rem; }

.answer {
  text-align: center;
  display: block; }

.answer__input {
  display: none;
  speak: none; }

.answer__input:checked + .answer__label {
  background-color: #617374;
  -webkit-box-shadow: inset 0 0.2rem #333c3c, inset 0 0.2rem 0.4rem #333c3c;
  box-shadow: inset 0 0.2rem #333c3c, inset 0 0.2rem 0.4rem #333c3c;
  color: #ecf0f1;
  cursor: pointer;
  top: 0.2rem; }

.answer__label {
  padding: 0.75rem 0.75rem 0.55rem 0.75rem; }
  @media screen and (min-width: 45em) {
    .answer__label {
      padding: 1.5rem 1.5rem 1.3rem 1.5rem; } }

.site-header {
  margin-bottom: 3rem;
  background-color: rgba(255, 255, 255, 0.98);
  border-bottom: 0.225rem solid #ecf0f1;
  display: block; }
  @media screen and (min-width: 30em) {
    .site-header {
      margin-bottom: 4.5rem; } }
  @media screen and (min-width: 45em) {
    .site-header {
      margin-bottom: 6rem; } }

.top-nav {
  text-align: center; }
  @media screen and (min-width: 60em) {
    .top-nav {
      text-align: right; } }

.top-nav__link {
  border-bottom: 0;
  display: block;
  float: left;
  font-weight: 400;
  line-height: 1.5rem;
  opacity: .5;
  padding: 0.5rem 0 1.5rem 0;
  width: 33.333333%; }
  @media screen and (min-width: 60em) {
    .top-nav__link {
      display: inline-block;
      float: none;
      width: auto;
      padding: 1.5rem 1.5rem 1.5rem 0; }
      .top-nav__link:last-of-type {
        padding-right: 1.5rem; } }
  @media screen and (min-width: 70em) {
    .top-nav__link {
      padding-right: 3rem; } }

.alpha,
h1,
.h1 {
  font-size: 40px; /*2.5 time 16 is 40!*/
  font-size: 2.5rem; }
  @media screen and (min-width: 45em) {
    .alpha,
    h1,
    .h1 {
      font-size: 56px;
      font-size: 3.5rem; } }
  @media screen and (min-width: 60em) {
    .alpha,
    h1,
    .h1 {
      font-size: 59.2px;
      font-size: 3.7rem; } }

.beta,
h2,
.h2 {
  font-size: 22.4px;
  font-size: 1.4rem; }
  @media screen and (min-width: 45em) {
    .beta,
    h2,
    .h2 {
      font-size: 32px;
      font-size: 2rem; } }
  @media screen and (min-width: 60em) {
    .beta,
    h2,
    .h2 {
      font-size: 35.2px;
      font-size: 2.2rem; } }

.gamma,
body,
h3,
.h3,
.how-do-I__grid h2 {
  font-size: 16px;
  font-size: 1rem; }
  @media screen and (min-width: 45em) {
    .gamma,
    body,
    h3,
    .h3,
    .how-do-I__grid h2 {
      font-size: 17.6px;
      font-size: 1.1rem; } }
  @media screen and (min-width: 60em) {
    .gamma,
    body,
    h3,
    .h3,
    .how-do-I__grid h2 {
      font-size: 19.2px;
      font-size: 1.2rem; } }
  @media screen and (min-width: 70em) {
    .gamma,
    body,
    h3,
    .h3,
    .how-do-I__grid h2 {
      font-size: 20.8px;
      font-size: 1.3rem; } }

.delta,
h4,
.h4,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
.btn,
.link-text-R,
.link-text-B,
.link-text-Y,
input[type="submit"],
.btn-lowercase,
.how-do-I__grid .grid__block--1-2-3 p,
.hints,
.top-nav__link {
  font-size: 12.8px;
  font-size: 0.8rem; }
  @media screen and (min-width: 45em) {
    .delta,
    h4,
    .h4,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="url"],
    .link-text-R,
    .link-text-B,
    .link-text-Y,
    .btn,
    input[type="submit"],
    .btn-lowercase,
    .how-do-I__grid .grid__block--1-2-3 p,
    .hints,
    .top-nav__link {
      font-size: 14.4px;
      font-size: 0.9rem; } }
  @media screen and (min-width: 60em) {
    .delta,
    h4,
    .h4,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="url"],
    .link-text-R,
    .link-text-B,
    .link-text-Y,
    .btn,
    input[type="submit"],
    .btn-lowercase,
    .how-do-I__grid .grid__block--1-2-3 p,
    .hints,
    .top-nav__link {
      font-size: 16px;
      font-size: 1rem; } }
  @media screen and (min-width: 70em) {
    .delta,
    h4,
    .h4,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="url"],
    .link-text-R,
    .link-text-B,
    .link-text-Y,
    .btn,
    input[type="submit"],
    .btn-lowercase,
    .how-do-I__grid .grid__block--1-2-3 p,
    .hints,
    .top-nav__link {
      font-size: 17.6px;
      font-size: 1.1rem; } }

.epsilon,
h5,
.h5 {
  font-size: 11.2px;
  font-size: 0.7rem; }
  @media screen and (min-width: 45em) {
    .epsilon,
    h5,
    .h5 {
      font-size: 12.8px;
      font-size: 0.8rem; } }

.zeta,
h6,
.h6 {
  font-size: 9.6px;
  font-size: 0.6rem; }
  @media screen and (min-width: 45em) {
    .zeta,
    h6,
    .h6 {
      font-size: 11.2px;
      font-size: 0.7rem; } }

.standfirst, .how-do-I header p {
  font-size: 20.8px;
  font-size: 1.3rem;
  line-height: 1.6875rem; }
  @media screen and (min-width: 30em) {
    .standfirst, .how-do-I header p {
      font-size: 22.4px;
      font-size: 1.4rem;
      line-height: 1.875rem; } }
  @media screen and (min-width: 45em) {
    .standfirst, .how-do-I header p {
      font-size: 25.6px;
      font-size: 1.6rem;
      line-height: 2.1rem; } }
  @media screen and (min-width: 70em) {
    .standfirst, .how-do-I header p {
      font-size: 28.8px;
      font-size: 1.8rem;
      line-height: 2.25rem; } }

.bold {
  font-weight: 600; }

.bold-inline, strong {
  font-weight: 600; }

.italic {
  font-style: italic; }

.center {
  text-align: center; }

.sc--lower, .btn,
input[type="submit"] {
  text-transform: uppercase;
  font-variant: small-caps;
  letter-spacing: .08rem; }

.heading-inline, .doc h2 {
  margin-bottom: 0.75rem;
  text-align: left; }

@media screen and (min-width: 45em) {
  .heading-big {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem; } }

.highlight--red {
  color: #e74c3c; }

.btn:hover, .btn:active, .btn:focus, input[type="submit"]:hover, input[type="submit"]:active, input[type="submit"]:focus {
  -webkit-transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 -webkit-box-shadow;
  transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 box-shadow;
  color: white; }

.sub-index:hover, .sub-index:active, .sub-index:focus {
  border-bottom-color: #ecf0f1; }
.sub-index:hover .sub-index__show, .sub-index:hover .sub-index__hide {
  color: #e74c3c; }
.sub-index:hover .sub-index__completion, .sub-index:focus .sub-index__completion {
  color: #111111; }

a {
  color: inherit;
  text-decoration: none;
  border-bottom: 0.15rem solid #cfd9db; }
  a:hover, a:active, a:focus {
    border-color: #e74c3c;
    text-decoration: none;
    -webkit-transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 -webkit-box-shadow;
    transition: 150ms background-color ease-in-out, 150ms color ease-in-out, 150ms border-bottom ease-in-out, 150ms opacity ease-in-out, 0 box-shadow; }

.link-2, .site-footer a {
  border-color: rgba(231, 76, 60, 0); }
  .link-2:hover, .link-2:active, .link-2:focus, .site-footer a:hover, .site-footer a:active, .site-footer a:focus {
    border-color: #e74c3c; }

.link-no-style, .btn,
input[type="submit"], .btn-lowercase, .hints a {
  text-decoration: none;
  border-bottom: 0; }
  .link-no-style:hover, .link-no-style:active, .link-no-style:focus, .btn:hover,
  input[type="submit"]:hover, .btn-lowercase:hover, .hints a:hover, .btn:active,
  input[type="submit"]:active, .btn-lowercase:active, .hints a:active, .btn:focus,
  input[type="submit"]:focus, .btn-lowercase:focus, .hints a:focus {
    text-decoration: none;
    border-bottom: 0; }

.link-btn {
  text-decoration: none;
  border-bottom: 0; }
  .link-btn:hover, .link-btn:active, .link-btn:focus {
    text-decoration: none;
    border-bottom: 0;
    color: white; }

.link-highlight {
  border-bottom-color: #e74c3c; }
  .link-highlight:hover, .link-highlight:active, .link-highlight:focus {
    color: #e74c3c;
    border-bottom-color: #e74c3c; }

.problem__hints--general a:hover, .problem__hints--general a:active, .problem__hints--general a:focus {
  background-color: #2980b9;
  color: #123851; }

.problem__hints--specific a:hover, .problem__hints--specific a:active, .problem__hints--specific a:focus {
  background-color: #27ae60;
  color: #104627; }

.top-nav__link:hover, .top-nav__link:active, .top-nav__link:focus {
  opacity: 1; }

.is-active {
  opacity: 1; }
  

/* Added by me */
/* line 104, ../../../../../Users/maxalfiemurdoch/Sites/iWantToStudyEngineering/source/scss/3. modules/buttons/_buttons.scss */
.btn--1 {
    border-radius: 0.3rem;
    box-shadow: 0 0.2rem #008;
    background-color: #00c;
    color: white;
    position: relative;
    text-shadow: 0 1px #00a;
}

.btn--1incor {
    border-radius: 0.3rem;
    box-shadow: 0 0.2rem #d62c1a;
    background-color: #e74c3c;
    color: white;
    position: relative;
    text-shadow: 0 1px #a82315;
}

.btn--1cor {
    border-radius: 0.3rem;
    box-shadow: 0 0.2rem #118833;
    background-color: #11dd44;
    color: white;
    position: relative;
    text-shadow: 0 1px #113311;
}

/* line 114, ../../../../../Users/maxalfiemurdoch/Sites/iWantToStudyEngineering/source/scss/3. modules/buttons/_buttons.scss */
.btn--1incor:hover,
.btn--1incor:focus {
    background-color: #ea6153;
    box-shadow: 0 0.2rem #e43725;
    color: white;
}

.btn--1cor:hover,
.btn--1cor:focus {
    background-color: #11dd44;
    box-shadow: 0 0.2rem #118833;
    color: white;
}

.btn--1:hover,
.btn--1:focus {
    background-color: #00c;
    box-shadow: 0 0.2rem #008;
    color: white;
}

/* line 121, ../../../../../Users/maxalfiemurdoch/Sites/iWantToStudyEngineering/source/scss/3. modules/buttons/_buttons.scss */
.btn--1:active {
    color: white;
    background-color: #e43725;
    box-shadow: none;
    top: 0.2rem;
}

/* line 129, ../../../../../Users/maxalfiemurdoch/Sites/iWantToStudyEngineering/source/scss/3. modules/buttons/_buttons.scss */
.btn--2 {
    border-radius: 0.3rem;
    box-shadow: 0 0.2rem #1e8449;
    background-color: #27ae60;
    color: white;
    text-shadow: 0 1px #145b32;
}

/* line 138, ../../../../../Users/maxalfiemurdoch/Sites/iWantToStudyEngineering/source/scss/3. modules/buttons/_buttons.scss */
.btn--2:hover,
.btn--2:focus {
    background-color: #2cc36b;
    box-shadow: 0 0.2rem #229955;
    color: white;
}

/* line 145, ../../../../../Users/maxalfiemurdoch/Sites/iWantToStudyEngineering/source/scss/3. modules/buttons/_buttons.scss */
.btn--2:active {
    color: white;
    background-color: #229955;
    box-shadow: none;
    top: 0.2rem;
}

/* line 153, ../../../../../Users/maxalfiemurdoch/Sites/iWantToStudyEngineering/source/scss/3. modules/buttons/_buttons.scss */
.btn--3 {
    border-radius: 0.3rem;
    box-shadow: 0 0.2rem #20638f;
    background-color: #2980b9;
    color: white;
    text-shadow: 0 1px #164666;
}

/* line 162, ../../../../../Users/maxalfiemurdoch/Sites/iWantToStudyEngineering/source/scss/3. modules/buttons/_buttons.scss */
.btn--3:hover,
.btn--3:focus {
    background-color: #2e8ece;
    box-shadow: 0 0.2rem #2472a4;
    color: white;
}

/* line 169, ../../../../../Users/maxalfiemurdoch/Sites/iWantToStudyEngineering/source/scss/3. modules/buttons/_buttons.scss */
.btn--3:active {
    color: white;
    background-color: #2472a4;
    box-shadow: none;
    top: 0.2rem;
}
/* End Added by me */

.acidjs-rating-stars,
.acidjs-rating-stars label::before
{
    display: inline-block;
}
 
.acidjs-rating-stars label:hover,
.acidjs-rating-stars label:hover ~ label
{
    color: #f5b301;
}
 
.acidjs-rating-stars *
{
    margin: 0;
    padding: 0;
}
 
.acidjs-rating-stars input
{
    display: none;
}
 
.acidjs-rating-stars
{
    unicode-bidi: bidi-override;
    direction: rtl;
}
 
.acidjs-rating-stars label
{
    color: #ccc;
}
 
.acidjs-rating-stars label::before
{
    content: "\2605";
    width: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
}
 
.acidjs-rating-stars input:checked ~ label
{
    color: #f5b301;
}
 
.acidjs-rating-disabled
{
    opacity: .50;
     
    -webkit-pointer-events: none;
    -moz-pointer-events: none;
    pointer-events: none;
}

/*****/

.acidjs-rating-stars-big,
.acidjs-rating-stars-big label::before
{
    display: inline-block;
}
 
.acidjs-rating-stars-big label:hover,
.acidjs-rating-stars-big label:hover ~ label
{
    color: #f5b301;
}
 
.acidjs-rating-stars-big *
{
    margin: 0;
    padding: 0;
}
 
.acidjs-rating-stars-big input
{
    display: none;
}
 
.acidjs-rating-stars-big
{
    unicode-bidi: bidi-override;
    direction: rtl;
}
 
.acidjs-rating-stars-big label
{
    color: #ccc;
}
 
.acidjs-rating-stars-big label::before
{
    content: "\2605";
    width: 50px;
    margin: 0 auto;
    line-height: 50px;
    text-align: center;
    font-size: 50px;
    cursor: pointer;
}

@media screen and (min-width: 30em) {
    .acidjs-rating-stars-big label::before {
        width: 30px;
    	line-height: 30px;
    	text-align: center;
    	font-size: 30px; } }
  @media screen and (min-width: 45em) {
    .acidjs-rating-stars-big label::before {
      	width: 40px;
    	line-height: 40px;
    	text-align: center;
    	font-size: 40px; } }
  @media screen and (min-width: 70em) {
    .acidjs-rating-stars-big label::before {
      	width: 50px;
    	line-height: 50px;
    	text-align: center;
    	font-size: 50px; } }
 
.acidjs-rating-stars-big input:checked ~ label
{
    color: #f5b301;
}

#review_comment
{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	
	-webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border:3px solid #000;
    
    font-size: 18px;
	resize: none;
	width: 100%;
}

#modal-priv {
    background:rgba(0,0,0,0.5);
    min-width:100%;
    min-height:100%;
    width:100%;
    height:100%;
    position: fixed;
    display: none;
    z-index: 1000;
}

#notice-priv {
    background:rgba(255,255,255,1);
    min-width:550px;
    min-height:480px;
    width:60%;
    height:50%;
    left: 20%;
    top: 20%;
    position: fixed;
    border-radius: 15px;
    border: 5px solid #666666;
}

.notice-h {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
}

#close-priv {
    position: relative;
    float: right;
    right: 15px;
}
.mdiv
{
height:25px;
width:2px;
margin-left:12px;
background-color:black;
transform: rotate(45deg);
-ms-transform: rotate(45deg); /* IE 9 */
-webkit-transform: rotate(45deg); /* Safari and Chrome */
Z-index:1;

}
.md
{
height:25px;
width:2px;

background-color:black;
transform: rotate(90deg);
-ms-transform: rotate(90deg); /* IE 9 */
-webkit-transform: rotate(90deg); /* Safari and Chrome */
Z-index:2;

}

.link-text-R {
  text-transform: uppercase;
  font-variant: small-caps;
  letter-spacing: .08rem;
  border-bottom: 0;
  color: #e74c3c;
  text-align: center;
  line-height: 1.2rem;
  outline: none;
}
.link-text-B {
  text-transform: uppercase;
  font-variant: small-caps;
  letter-spacing: .08rem;
  border-bottom: 0;
  color: #2980b9;
  text-align: center;
  line-height: 1.2rem;
  outline: none;
}
.link-text-Y {
  text-transform: uppercase;
  font-variant: small-caps;
  letter-spacing: .08rem;
  border-bottom: 0;
  color: #df9900;
  text-align: center;
  line-height: 1.2rem;
  outline: none;
}

.b-title {
   }
  @media screen and (min-width: 30em) {
    .b-title {
      height: 150px;
  } }
  @media screen and (min-width: 45em) {
    .b-title {
      height: 140px;
  } }
  @media screen and (min-width: 70em) {
    .b-title {
      height: 150px;
    }
  }

.b-middle {
   }
  @media screen and (min-width: 30em) {
    .b-middle {
      height: 180px;
  } }
  @media screen and (min-width: 45em) {
    .b-middle {
      height: 170px;
  } }
  @media screen and (min-width: 70em) {
    .b-middle {
      height: 180px;
    }
  }

.b-top {
   }
  @media screen and (min-width: 30em) {
    .b-top {
      height: 180px;
  } }
  @media screen and (min-width: 45em) {
    .b-top {
      height: 170px;
  } }
  @media screen and (min-width: 70em) {
    .b-top {
      height: 180px;
    }
  }

